<template>
  <div class="accordion">

    <div style="background: #16a37f;padding:10px;color:#fff;">
      <span style="display: block;width:10%;display: block;float: left;" @click="$router.go(-1)">返回</span>
      <span style="text-align: center;width: 90%;display: block;font-size: 0.16rem;">帮助</span>
    </div>

    <div class="accordion-item" v-for="(item, index) in items" :key="index">
      <div class="accordion-header" @click="toggle(index)">
        {{ item.title }}
        <span v-if="isOpen(index)">&#9650;</span>
        <span v-else>&#9660;</span>
      </div>
      <div class="accordion-content" :class="{ 'is-open': isOpen(index) }" style="whiteSpace: pre-line;">
        {{ item.content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: null,
      items: [
        {
          title: '助手AI可以做什么',
          content: '助手AI提供通用的人工智能对话能力，即能够回答或实现您各种各样的问题和需求，其中包括但不限于解释/编写代码、编写/优化各种文案或文章、解答日常问题、按要求实现您的需求等。由于技术与其他制约因素，助手AI的回答只供参考，我们不赞同或支持助手AI的任何内容。\n' +
              '\n' +
              '助手AI的初衷是使人们更便捷的享受到人工智能技术发展的成果，我们不支持任何违法活动，同时我们也在努力限制违法内容。我们已接入内容安全审查，请大家文明、合理的使用。'
        },
        {
          title: '付费会员和免费会员有什么区别？',
          content: '免费会员提问次数有限制，付费会员提问次数不限。'
        },
        {
          title: '为什么有时候会出现“请稍后重试”',
          content: '出现请稍后重试可能是由于网络异常而导致。如果偶尔出现请不必在意，重新提问尝试即可。如果重试多次还是回答出错，有可能是对话人数过多，系统过载。也请不必担心，我们的技术人员会时刻留意这样的情况，发现即会第一时间处理，稍等一段时间便可恢复。'
        },
        {
          title: '助手AI免费使用吗？',
          content: '助手AI是会员制付费使用的，如果您想使用我们的完整功能请开通会员。'
        },
        {
          title: '电脑端与手机端都可以使用吗？',
          content: '请牢记我们的公众号：助手AI，网址域名 https://www.wph3.cn 通过手机浏览器和电脑浏览器，微信公众号都可以随时访问使用。'
        },
        {
          title: '为什么有时候回答的内容不完整？',
          content: '如出现回答不完整，您可以回复继续。'
        },
        {
          title: '为什么我上面的对话记录没了？如何清除对话记录？',
          content: '因为助手AI能够根据上下文进行回答，所以会有一个“会话”的概念。对话记录在当次会话中保存，进入新会话则清空上一次会话的记录。\n' +
              '\n' +
              '当您刚进来进行第一个提问的时候遍会自动开始一个会话，在会话中您可以提问无数个问题，在当次会话中对话记录会一直存在。若您1小时内没有新的提问，则自动结束这次会话，对话记录也会自动清空。当下一次提问又会自动开启一个新会话。\n' +
              '\n' +
              '会话与对话记录的保留规则如上，此外无法手动清除对话记录或结束会话。'
        },
        {
          title: '助手AI能根据上下文回答吗？',
          content: '助手AI是能根据上下文回答的，在当次会话中助手AI会最多根据5条上下文内容理解最后做出回答。所以如果您的提问如没有得到您想要的答复，您可以继续追问。'
        },
        {
          title: '网站功能异常或其他问题反馈，请联系客服',
          content: '如果需要人工客服帮助，请联系客服QQ:1053521470'
        }
      ]
    }
  },
  methods: {
    toggle(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null
      } else {
        this.activeIndex = index
      }
    },
    isOpen(index) {
      return this.activeIndex === index
    }
  }
}
</script>

<style scoped>
.accordion {
  width: 100%;
}
.accordion-item {
  border: 1px solid #ccc;
  border-bottom: none;
}
.accordion-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size:.16rem;
}
.accordion-content {
  height: 0;
  line-height:24px;
  overflow: hidden;
  transition: height 0.3s ease-out;
}
.accordion-content.is-open {
  height: auto;
  padding: 0 15px 15px;
}
</style>
